import { useReducer } from 'react'
import QuarantineNameGeneratorState from './../types/QuarantineNameGeneratorState'

type Action = {
  type: 'GENERATE_WORDS'
  value: QuarantineNameGeneratorState
}

const wordListOne = [
  'acute',
  'intense',
  'maximal',
  'severe',
  'supreme',
  'ultimate',
  'extreme',
  'absolute',
  'drastic',
  'extraordinary',
  'sheer',
  'uttermost',
  'utmost',
  'hybrid',
  'heterogenous',
  'compound',
  'diverse',
  'indiscriminate',
  'basta',
  'extrajudicial',
  'legalized',
  'medyo',
  'normalized',
  'super duper',
  'ultraelectromagnetic',
]

const wordListTwo = [
  'augmented',
  'boosted',
  'heightened',
  'increased',
  'intensified',
  'raised',
  'reinforced',
  'strengthened',
  'upgraded',
  'amplified',
  'exaggerated',
  'magnified',
  'enhanced',
  'general',
  'noisy',
  'vanilla',
  'dirty',
  'crazy',
  'sloppy',
  'dry',
  'casual',
  'hard',
  'raw',
  'rough',
  'violent',
  'cold',
]

const useGenerateWord = (initialState: QuarantineNameGeneratorState) => {
  const reducer = (state: QuarantineNameGeneratorState, action: Action) => {
    const { type, value } = action
    switch (type) {
      case 'GENERATE_WORDS':
        return {
          ...state,
          wordOne: value.wordOne,
          wordTwo: value.wordTwo,
          sequel: value.sequel,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { wordOne, wordTwo, sequel }: QuarantineNameGeneratorState = state

  const generateWord = (wordList: string[]): string => {
    const index = Math.floor(Math.random() * Math.floor(wordList.length))
    return wordList[index]
  }

  const isSequel = (): boolean => {
    return Math.floor(Math.random() * 100) <= 5
  }

  const generateWords = () => {
    dispatch({
      type: 'GENERATE_WORDS',
      value: {
        wordOne: generateWord(wordListOne),
        wordTwo: generateWord(wordListTwo),
        sequel: isSequel(),
      },
    })
  }

  return { generateWords, wordOne, wordTwo, sequel }
}

export default useGenerateWord
