import React from 'react'
import { Layout } from '../components'
import useGenerateWord from './../hooks/useGenerateWord'
import QuarantineNameGeneratorState from './../types/QuarantineNameGeneratorState'

const QuarantineNameGenerator = () => {
  const initialState: QuarantineNameGeneratorState = {
    wordOne: '',
    wordTwo: '',
    sequel: false,
  }

  const { wordOne, wordTwo, generateWords, sequel } = useGenerateWord(
    initialState
  )

  return (
    <Layout
      pageTitle='Philippine Quarantine Name Generator'
      pageDescription='Generate your own Philippine quarantine name!'
    >
      <main className='container max-w-4xl pt-10 mx-auto text-center'>
        <h3 className='font-semibold tracking-wider font-display'>
          Tang ina, ECQ nanaman?{' '}
          <a
            href='https://twitter.com/search?q=%23DutertePalpak'
            className='text-red-600 hover:text-red-800'
          >
            #DutertePalpak
          </a>{' '}
          <a
            href='https://twitter.com/search?q=%23DuterteResign'
            className='text-red-600 hover:text-red-800'
          >
            #DuterteResign
          </a>{' '}
        </h3>
        <h1 className='mb-8 text-3xl font-semibold tracking-tight font-display'>
          Philippine Quarantine Name Generator
        </h1>
        <p className='block w-3/4 p-8 mx-auto mb-8 text-2xl font-bold tracking-wide border-2 border-black border-dashed'>
          {wordOne ? <span className='underline'>{wordOne}</span> : '------'}{' '}
          {wordTwo ? <span className='underline'>{wordTwo}</span> : '------'}{' '}
          community quarantine {sequel && '2: Electric Boogaloo'}
        </p>
        <button
          onClick={() => generateWords()}
          className='p-4 font-semibold text-white bg-black border-2 border-transparent hover:border-black hover:bg-transparent hover:text-black rounded-md transition ease-out duration-150'
        >
          Generate quarantine name
        </button>
      </main>
    </Layout>
  )
}

export default QuarantineNameGenerator
